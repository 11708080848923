const Network = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["rect", { x: "9", y: "2", width: "6", height: "6" }],
    ["rect", { x: "16", y: "16", width: "6", height: "6" }],
    ["rect", { x: "2", y: "16", width: "6", height: "6" }],
    ["path", { d: "M12 8V12M12 12H5V16M12 12H19V16" }]
  ]
];

export default Network;
