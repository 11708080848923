const Shovel = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["path", { d: "M2 22v-5l5-5 5 5-5 5z" }],
    ["path", { d: "M9.5 14.5L16 8" }],
    [
      "path",
      { d: "M17 2l5 5-.5.5a3.53 3.53 0 01-5 0s0 0 0 0a3.53 3.53 0 010-5L17 2" }
    ]
  ]
];

export default Shovel;
