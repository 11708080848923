const FlaskRound = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["path", { d: "M10 2v7.31" }],
    ["path", { d: "M14 9.3V1.99" }],
    ["path", { d: "M8.5 2h7" }],
    ["path", { d: "M14 9.3a6.5 6.5 0 11-4 0" }],
    ["path", { d: "M5.58 16.5h12.85" }]
  ]
];

export default FlaskRound;
