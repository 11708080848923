const FileX2 = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["path", { d: "M4 22h14a2 2 0 002-2V7.5L14.5 2H6a2 2 0 00-2 2v4" }],
    ["path", { d: "M14 2v6h6" }],
    ["path", { d: "M3 12.5l5 5" }],
    ["path", { d: "M8 12.5l-5 5" }]
  ]
];

export default FileX2;
