const QrCode = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["rect", { x: "2", y: "2", width: "8", height: "8" }],
    ["path", { d: "M6 6H6.01" }],
    ["rect", { x: "14", y: "2", width: "8", height: "8" }],
    ["path", { d: "M18 6H18.01" }],
    ["rect", { x: "2", y: "14", width: "8", height: "8" }],
    ["path", { d: "M6 18H6.01" }],
    ["path", { d: "M14 14H14.01" }],
    ["path", { d: "M18 18H18.01" }],
    ["path", { d: "M18 22L22 22L22 18" }],
    ["path", { d: "M14 18V22" }],
    ["path", { d: "M22 14L18 14" }]
  ]
];

export default QrCode;
