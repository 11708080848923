const Languages = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["line", { x1: "5", y1: "8", x2: "11", y2: "14" }],
    ["path", { d: "M4 14l6-6 2-3" }],
    ["line", { x1: "2", y1: "5", x2: "14", y2: "5" }],
    ["line", { x1: "7", y1: "2", x2: "8", y2: "2" }],
    ["path", { d: "M22 21l-5-10-5 10" }],
    ["line", { x1: "14", y1: "17", x2: "20", y2: "17" }]
  ]
];

export default Languages;
