const Palette = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["circle", { cx: "13.5", cy: "6.5", r: "0.5" }],
    ["circle", { cx: "17.5", cy: "10.5", r: "0.5" }],
    ["circle", { cx: "8.5", cy: "7.5", r: "0.5" }],
    ["circle", { cx: "6.5", cy: "12.5", r: "0.5" }],
    [
      "path",
      {
        d:
          "M12 2.0C6.5 2.0 2 6.5 2 12C2 17.5 6.5 22 12 22C12.9258 22 13.6484 21.2539 13.6484 20.3125C13.6484 19.875 13.4687 19.4766 13.2109 19.1875C12.9219 18.8984 12.7734 18.5352 12.7734 18.0625C12.7734 17.1172 13.5 16.3945 14.4414 16.3945H16.4375C19.4883 16.3945 21.9922 13.8906 21.9922 10.8398C21.9648 6.01172 17.4609 2.0 12 2.0Z"
      }
    ]
  ]
];

export default Palette;
