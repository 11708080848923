const Outdent = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["polyline", { points: "7 8 3 12 7 16" }],
    ["line", { x1: "21", y1: "12", x2: "11", y2: "12" }],
    ["line", { x1: "21", y1: "6", x2: "11", y2: "6" }],
    ["line", { x1: "21", y1: "18", x2: "11", y2: "18" }]
  ]
];

export default Outdent;
