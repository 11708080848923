const LayoutDashboard = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["rect", { x: "3", y: "3", width: "7", height: "9" }],
    ["rect", { x: "14", y: "3", width: "7", height: "5" }],
    ["rect", { x: "14", y: "12", width: "7", height: "9" }],
    ["rect", { x: "3", y: "16", width: "7", height: "5" }]
  ]
];

export default LayoutDashboard;
