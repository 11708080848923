const BatteryCharging = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    [
      "path",
      {
        d:
          "M7 7H3.78A1.73 1.73 0 0 0 2 8.67v6.66A1.73 1.73 0 0 0 3.78 17H6m8-10h2.22A1.73 1.73 0 0 1 18 8.67v6.66A1.73 1.73 0 0 1 16.22 17H13"
      }
    ],
    ["polyline", { points: "11 7 8 12 12 12 9 17" }],
    ["line", { x1: "22", x2: "22", y1: "11", y2: "13" }]
  ]
];

export default BatteryCharging;
