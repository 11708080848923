const FlashlightOff = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["path", { d: "M16 16v4a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V10C8 8 6 8 6 6" }],
    ["path", { d: "M7 2H18V6c0 2-2 2-2 4v1" }],
    ["line", { x1: "11", y1: "6", x2: "18", y2: "6" }],
    ["line", { x1: "2", y1: "2", x2: "22", y2: "22" }]
  ]
];

export default FlashlightOff;
