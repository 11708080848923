const Redo = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["polyline", { points: "22 10 22 16 16 16" }],
    [
      "path",
      { d: "m1.9935 16c1.8136-6.5424 9.5593-9.4823 15.36-4.36l4.64 4.36" }
    ]
  ]
];

export default Redo;
