const MoveHorizontal = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["polyline", { points: "18 8 22 12 18 16" }],
    ["polyline", { points: "6 8 2 12 6 16" }],
    ["line", { x1: "2", y1: "12", x2: "22", y2: "12" }]
  ]
];

export default MoveHorizontal;
