const Brush = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    [
      "path",
      {
        d:
          "M9.06 11.909l8.07-8.073a2.852 2.852 0 014.034 0 2.855 2.855 0 010 4.036l-8.069 8.073m-6.027-1.009c-1.66 0-3.001 1.352-3.001 3.028 0 1.322-2.501 1.513-2 2.018 1.08 1.09 2.49 2.018 4 2.018 2.212 0 4.002-1.806 4.002-4.036 0-1.676-1.34-3.028-3-3.028z"
      }
    ]
  ]
];

export default Brush;
