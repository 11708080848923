const Calculator = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["rect", { x: "4", y: "2", width: "16", height: "20", rx: "2" }],
    ["line", { x1: "8", x2: "16", y1: "6", y2: "6" }],
    ["line", { x1: "16", x2: "16", y1: "14", y2: "18" }],
    ["path", { d: "M16 10H16.01" }],
    ["path", { d: "M12 10H12.01" }],
    ["path", { d: "M8 10H8.01" }],
    ["path", { d: "M12 14H12.01" }],
    ["path", { d: "M8 14H8.01" }],
    ["path", { d: "M12 18H12.01" }],
    ["path", { d: "M8 18H8.01" }]
  ]
];

export default Calculator;
