const Wifi = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["path", { d: "M5 13a10 10 0 0114 0" }],
    ["path", { d: "M8.5 16.5a5 5 0 017 0" }],
    ["path", { d: "M2 8.82a15 15 0 0120 0" }],
    ["line", { x1: "12", y1: "20", x2: "12.01", y2: "20" }]
  ]
];

export default Wifi;
