const SortDesc = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["path", { d: "M11 5h4" }],
    ["path", { d: "M11 9h7" }],
    ["path", { d: "M11 13h10" }],
    ["path", { d: "M3 17l3 3 3-3" }],
    ["path", { d: "M6 18V4" }]
  ]
];

export default SortDesc;
