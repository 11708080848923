const Ruler = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["path", { d: "m16.0 2.0 6 6-14 14-6-6 14-14" }],
    ["path", { d: "m7.5 10.5 2 2" }],
    ["path", { d: "m10.5 7.5 2 2" }],
    ["path", { d: "m13.5 4.5 2 2" }],
    ["path", { d: "m4.5 13.5 2 2" }]
  ]
];

export default Ruler;
