const Copyright = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["circle", { cx: "12", cy: "12", r: "10" }],
    ["path", { d: "M15 9.354a4 4 0 1 0 0 5.292" }]
  ]
];

export default Copyright;
