const BatteryLow = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["rect", { x: "2", y: "7", width: "16", height: "10", rx: "2", ry: "2" }],
    ["line", { x1: "22", x2: "22", y1: "11", y2: "13" }],
    ["line", { x1: "6", x2: "6", y1: "10", y2: "14" }]
  ]
];

export default BatteryLow;
