const Axe = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    [
      "path",
      {
        d:
          "m14 12-8.501 8.501c-0.828 0.828-2.17 0.828-2.998 0-1e-3 -1e-3 -1e-3 -1e-3 -2e-3 -0-0.828-0.828-0.828-2.17 0-2.998l8.501-8.501"
      }
    ],
    [
      "path",
      {
        d:
          "m9 7 4-4 6 6h3s-0.051 0.254-0.13 0.648c-0.538 2.691-2.477 4.888-5.081 5.756-1.003 0.334-1.789 0.596-1.789 0.596v-3z"
      }
    ]
  ]
];

export default Axe;
