const FastForward = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["polygon", { points: "13 19 22 12 13 5 13 19" }],
    ["polygon", { points: "2 19 11 12 2 5 2 19" }]
  ]
];

export default FastForward;
