const ListChecks = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["line", { x1: "10", y1: "6", x2: "21", y2: "6" }],
    ["line", { x1: "10", y1: "12", x2: "21", y2: "12" }],
    ["line", { x1: "10", y1: "18", x2: "21", y2: "18" }],
    ["polyline", { points: "3 6 4 7 6 5" }],
    ["polyline", { points: "3 12 4 13 6 11" }],
    ["polyline", { points: "3 18 4 19 6 17" }]
  ]
];

export default ListChecks;
