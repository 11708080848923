const AlarmClock = [
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    fill: "none",
    stroke: "currentColor",
    "stroke-width": 2,
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  },
  [
    ["circle", { cx: "12", cy: "13", r: "8" }],
    ["path", { d: "M12 9v4l2 2" }],
    ["path", { d: "M5 3L2 6" }],
    ["path", { d: "M22 6l-3-3" }],
    ["path", { d: "M6 19l-2 2" }],
    ["path", { d: "M18 19l2 2" }]
  ]
];

export default AlarmClock;
